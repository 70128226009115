import React from 'react';
import SideMenu from '../side-menu';
import './style.scss';

const Page = (props) => {
  const page = props.data.markdownRemark;

  return (
    <div>
      <SideMenu location={props.location} siteMetadata={props.data.site.siteMetadata} />
      <div className="content">
        <div className="content__inner">
          <div className="page">
            <h1 className="page__title">{page.frontmatter.title}</h1>
            <div
              className="page__body"
                /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: page.html }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
