import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Root from '../../components/root';
import PageTemplateDetails from '../../components/page';

const PageTemplate = (props) => {
  const { subtitle } = props.data.site.siteMetadata;
  const page = props.data.markdownRemark;
  const { title: pageTitle, description: pageDescription, path } = page.frontmatter;
  const description = pageDescription !== null ? pageDescription : subtitle;

  return (
    <Root>
      <div>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={`https://ianbelcher.me${path}`} />
        </Helmet>
        <PageTemplateDetails {...props} />
      </div>
    </Root>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteTitle
        subtitle
        copyright
        menu {
          label
          path
          link
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        type
        title
        date
        description
      }
    }
  }
`;
